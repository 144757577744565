import React, { useState } from 'react';
import { graphql,Link } from 'gatsby';
import styled from 'styled-components';
import TwoColLayout from '../components/layout/TwoColLayout';
import {Helmet} from "react-helmet";
import '@suziwen/gitalk/dist/gitalk.css'
import SearchBar from '../components/views/SearchBar';
import { useFlexSearch } from 'react-use-flexsearch';
import moment from 'moment';

export default function SearchPage({data}:{data: any}) {
  const MainContent = styled.div`
    .pankuzu{ font-size:12px; }
    .pankuzu a{ text-decoration: none; color:#333; }
    .page-header{ background-color: #333; padding: 30px; }
    .page-header .gatsby-image-wrapper{ border-radius: 5px; }
    .page-header h1{ font-size: 26px; font-weight: bold; color: white; }
    .gt-container{ margin-bottom: 40px; }
  `;
  let results = [];
  if (typeof window !== `undefined`) {
    const { search } = window.location;
    const query = new URLSearchParams(search).get('s')
    const [searchQuery, setSearchQuery] = useState(query || '');
    results = useFlexSearch(
      searchQuery, 
      data.localSearchPages.index, 
      data.localSearchPages.store
    );
  }
  return (
    <TwoColLayout
      data={data}
      main={
        <MainContent>
          <Helmet>
            <html lang="ja" />
            <title>検索 | TERAKOYA</title>
            <meta name="description" content="ITテックブログです" />
          </Helmet>
          <h1>検索一覧</h1>
          <SearchBar/>
          {results.map(post => (
            <div key={post.id}>
              <h2>
                <Link to={`/${post.slug}`}>{post.title}</Link>
              </h2>
              <div className='post-meta'>
                <span className='date'>
                    {moment(post.date).format(`YYYY年M月D日 H:mm`)}
                </span>
                <br />
              </div>
            </div>
          ))}
        </MainContent>
      }
      side={
        <div>
          サイドメニュー
        </div>
      }
    />
  );
}

export const query = graphql`
  query{
    site {
      siteMetadata {
        title
        headerLinks {
          label
          url
        }
      }
    }
    localSearchPages {
      index
      store
    }
    allMdx(
      sort: { order: DESC, fields: frontmatter___date }, 
      filter: { 
        frontmatter: { 
          publish: { eq: true } 
          posttype: { eq: "issue" } 
        } 
      }
    ) {
      nodes {
        id
        frontmatter {
          slug
          date
          title
          posttype
          icon {
            childImageSharp {
              gatsbyImageData
            }
          }
          tags
        }
        parent {
          ... on File {
            name
          }
        }
      }
    }
  }
`;

